import {
  Business,
  LensPlatformClient,
  NotFoundException,
  LensSDKException,
  ForbiddenException,
} from "lens-platform-sdk";
import { omit } from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "src/queryClient";
import { useCallback, useState } from "react";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useAnalytics } from "./useAnalytics";
import { type BusinessUpdate, type BusinessReplace } from "lens-platform-sdk";

/**
 * Type for creating a new business entity.
 */
export type NewBusiness = Parameters<LensPlatformClient["business"]["createOne"]>[0];

const unAllowedUpdateBusinessEntityKeys: Array<keyof Business> = [
  "id",
  "businessIdLiteSubscriptionId",
  "external",
  "businessUsers",
  "emailDomain",
];

/**
 * Hooks for CRUD business entities (Lens Business IDs).
 */
export const useBusiness = (businessId?: string) => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  // See https://tkdodo.eu/blog/react-query-and-type-script#type-safety-with-the-enabled-option
  const fetchBusiness = useCallback(
    (id?: string) =>
      typeof id === "undefined"
        ? Promise.reject(new Error("Invalid businessId"))
        : lensPlatformClient.business.getOne(id),
    [lensPlatformClient],
  );

  const {
    isLoading: loadingBusiness,
    error: errorLoadingBusiness,
    data: business,
    refetch: refetchBusiness,
  } = useQuery<Business, LensSDKException>({
    queryKey: ["business", businessId, fetchBusiness],
    queryFn: () => fetchBusiness(businessId),
    retry: (_, error) => {
      if (error instanceof NotFoundException || error instanceof ForbiddenException) {
        return false;
      }

      return true;
    },
    enabled: Boolean(businessId),
  });

  const {
    isLoading: loadingBusinesses,
    error: errorLoadingBusinesses,
    data: businesses,
    refetch: refetchBusinesses,
  } = useQuery<Business[], LensSDKException>({
    queryKey: ["businesses"],
    queryFn: () => lensPlatformClient.business.getMany(),
    retry: (_, error) => {
      if (error instanceof NotFoundException || error instanceof ForbiddenException) {
        return false;
      }

      return true;
    },
    initialData: [],
  });

  const {
    isPending: updatingBusiness,
    isSuccess: updateBusinessSuccess,
    error: errorUpdatingBusiness,
    mutate: updateBusiness,
  } = useMutation<Business | undefined, LensSDKException, BusinessUpdate | undefined>({
    mutationFn: async (updateBusiness?: BusinessUpdate) => {
      if (!businessId || typeof updateBusiness === "undefined") {
        return undefined;
      }

      const updatedBusiness = await lensPlatformClient.business.updateOne(
        businessId,
        omit(updateBusiness, unAllowedUpdateBusinessEntityKeys),
      );

      return updatedBusiness;
    },

    onSuccess: async () => {
      track("BusinessID updated", { businessId });
      await queryClient.invalidateQueries({ queryKey: ["business", businessId] });
      await queryClient.invalidateQueries({ queryKey: ["business"] });
    },
  });

  const {
    isPending: replacingBusiness,
    isSuccess: replaceBusinessSuccess,
    error: errorReplacingBusiness,
    mutate: replaceBusiness,
  } = useMutation<Business | undefined, LensSDKException, BusinessReplace | undefined>({
    mutationFn: async (replaceBusiness?: BusinessReplace) => {
      if (!businessId || typeof replaceBusiness === "undefined") {
        return undefined;
      }

      const replacedBusiness = await lensPlatformClient.business.replaceOne(businessId, replaceBusiness);

      return replacedBusiness;
    },

    onSuccess: async () => {
      track("BusinessID replaced", { businessId });
      await queryClient.invalidateQueries({ queryKey: ["business", businessId] });
      await queryClient.invalidateQueries({ queryKey: ["business"] });
    },
  });

  const [newlyCreatedBusiness, setNewlyCreatedBusiness] = useState<Business>();

  const {
    isPending: creatingBusiness,
    isSuccess: createBusinessSuccess,
    error: errorCreatingBusiness,
    mutate: createBusiness,
  } = useMutation<Business | undefined, LensSDKException, NewBusiness>({
    mutationFn: async (newBusiness: NewBusiness) => lensPlatformClient.business.createOne(newBusiness),

    onSuccess: async (newBusiness) => {
      setNewlyCreatedBusiness(newBusiness);
      track("BusinessID created", { businessId: newBusiness?.id });
      await queryClient.invalidateQueries({ queryKey: ["businesses"] });
    },
  });

  const {
    isPending: deletingBusiness,
    error: errorDeletingBusiness,
    mutate: deleteBusiness,
  } = useMutation({
    mutationFn: async (id: string) => lensPlatformClient.business.deleteOne(id),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["business", businessId] });
      await queryClient.invalidateQueries({ queryKey: ["business"] });
    },
  });

  return {
    // GET (one)
    loadingBusiness,
    errorLoadingBusiness,
    business,
    refetchBusiness,
    // GET (all)
    loadingBusinesses,
    errorLoadingBusinesses,
    businesses,
    refetchBusinesses,
    // POST
    creatingBusiness,
    errorCreatingBusiness,
    createBusiness,
    createBusinessSuccess,
    newlyCreatedBusiness,
    // PATCH
    updatingBusiness,
    errorUpdatingBusiness,
    updateBusiness,
    updateBusinessSuccess,
    // PUT
    replacingBusiness,
    errorReplacingBusiness,
    replaceBusiness,
    replaceBusinessSuccess,
    // DELETE
    deletingBusiness,
    errorDeletingBusiness,
    deleteBusiness,
  };
};
