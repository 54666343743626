import type { BusinessSubscription } from "lens-platform-sdk";
import { LensSDKException } from "lens-platform-sdk";
import { useQuery } from "@tanstack/react-query";
import { useLensPlatformClient } from "./useLensPlatformClient";

/**
 * Get user's business entity (Lens Business IDs)'s subscriptions by business entity ID.
 *
 * @param includeChildrenSubscriptions to include subscriptions from child businesses, defaults to false
 */
export const useGetBusinessSubscriptions = (businessId?: string, includeChildrenSubscriptions = false) => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isLoading: loading,
    isFetching: fetching,
    error,
    data: businessSubscriptions,
    refetch,
  } = useQuery<BusinessSubscription[], LensSDKException>({
    queryKey: ["businessSubscriptions", businessId, includeChildrenSubscriptions],
    queryFn: async () => {
      if (!businessId) {
        return [];
      }
      const businessSubscriptions = await lensPlatformClient.business.getSubscriptions(businessId);

      if (includeChildrenSubscriptions === true) {
        return businessSubscriptions;
      }

      return businessSubscriptions.filter((subscription) => !subscription.fromChildBusinessId);
    },
    initialData: [],
  });

  return {
    loading: loading || fetching,
    error,
    businessSubscriptions,
    reload: refetch,
  };
};
