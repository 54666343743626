import clsx from "clsx";
import { ProgressBar } from "@k8slens/lds";

import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";
import { useSubscriptionSeatCount } from "src/hooks/useSubscriptionSeatCount";

import Link from "src/components/TrackedLink/TrackedLink";

import styles from "./Analytics.module.css";
import { useBusinessJoinRequest } from "src/hooks/useBusinessJoinRequest";

export function Analytics({ children }: { children?: React.ReactNode }) {
  return (
    <section>
      <h3 className="lds-h4">Analytics</h3>
      <div className={styles.analytics}>{children}</div>
    </section>
  );
}

export function SeatCount() {
  const businessId = useBusinessIdParam();
  const { seatCount } = useSubscriptionSeatCount(businessId);

  const noSeatsAssigned = seatCount.total > 0 && seatCount.inUse === 0;
  const seatsFull = seatCount.inUse >= seatCount.total;

  return (
    <div className={styles.analyticsBlock}>
      <div className={styles.description}>
        <span className={styles.caps}>{`${seatCount.inUse}/${seatCount.total}`} seats assigned</span>
        {noSeatsAssigned || seatsFull ? <span>-</span> : null}
        {noSeatsAssigned ? (
          <Link to={`/business/${businessId}/users`} className={clsx("primary", styles.caps)}>
            Invite users
          </Link>
        ) : null}
        {seatsFull ? (
          <Link to={`/business/${businessId}/billing-details`} className={clsx("primary", styles.caps)}>
            Add seats
          </Link>
        ) : null}
      </div>
      <ProgressBar className={styles.progressBar} value={seatCount.inUse} max={seatCount.total} type="ok" />
    </div>
  );
}

export function JoinRequest() {
  const businessId = useBusinessIdParam();

  const { count } = useBusinessJoinRequest(businessId, "pending");

  return (
    <div className={styles.analyticsBlock}>
      <div className={styles.description}>
        <span className={styles.caps}>
          {count} Join Request{count === 1 ? "" : "s"}
        </span>
        {count >= 1 ? (
          <Link to={`/business/${businessId}/users/requests`} className={clsx("primary", styles.caps)}>
            Review
          </Link>
        ) : null}
      </div>
    </div>
  );
}
