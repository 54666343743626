import { Analytics, JoinRequest, SeatCount } from "./Analytics";
import {
  WhatNext,
  InviteUser,
  ConfigureSSO,
  ConfigureSCIM,
  SetUpDomainMatching,
  PurchaseSubscription,
} from "./WhatNext";
import PageHeader from "src/components/PageHeader/PageHeader";
import { Catalog } from "./Catalog";

import styles from "./page.module.css";
import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";
import { useBooleanFlagValue } from "@openfeature/react-sdk";

export function BusinessHome() {
  const businessId = useBusinessIdParam();
  const domainMatchingSettingsFlag = useBooleanFlagValue("show-domain-matching-settings", false);

  return (
    <>
      <PageHeader
        title="Welcome to your Lens Business ID"
        subtitle={
          <span>
            Your home for managing Lens Subscriptions and settings for organization <strong>{`(${businessId})`}</strong>
            .
          </span>
        }
      />
      <div className={styles.intro}>
        <Analytics>
          <SeatCount />
          <JoinRequest />
        </Analytics>
        <WhatNext>
          {domainMatchingSettingsFlag ? <SetUpDomainMatching /> : null}
          <InviteUser />
          <PurchaseSubscription />
          <ConfigureSSO />
          <ConfigureSCIM />
        </WhatNext>
      </div>
      <Catalog />
    </>
  );
}
