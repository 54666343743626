import { useGetBusinessSubscriptions } from "./useGetBusinessSubscriptions";

export const useHasPurchasedSubscription = (businessId: string) => {
  const { businessSubscriptions, loading, error } = useGetBusinessSubscriptions(businessId);

  return {
    hasPurchasedSubscription:
      businessSubscriptions.filter(({ state }) => state === "active" || state === "canceled").length > 0,
    loading,
    error,
  };
};
