import clsx from "clsx";
import type { LinkProps } from "react-router-dom";

import Link from "src/components/TrackedLink/TrackedLink";

import { useHasInvitedUser } from "src/hooks/useHasInvitedUser";
import { useIsSCIMActive } from "src/hooks/useIsSCIMActive";
import { useIsSSOActive } from "src/hooks/useIsSSOActive";
import { base } from "@k8slens/lds-icons";
import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";

import styles from "./WhatNext.module.css";
import { useIsDomainMatchingOn } from "src/hooks/useIsDomainMatchingOn";
import { useHasPurchasedSubscription } from "src/hooks/useHasPurchasedSubscription";
import { pricingUrl } from "src/constants";
import { openUrl } from "src/utils/url";
import { useBusiness } from "src/hooks/useBusiness";
import { useBusinessUsers } from "src/hooks/useBusinessUsers";

const { CheckIcon } = base;

export function WhatNext({ children }: { children?: React.ReactNode }) {
  const businessId = useBusinessIdParam();
  const { hasInvitedUser } = useHasInvitedUser(businessId);
  const { isSSOActive } = useIsSSOActive(businessId);
  const { isSCIMActive } = useIsSCIMActive(businessId);
  const { isDomainMatchingOn } = useIsDomainMatchingOn(businessId);
  const { hasPurchasedSubscription } = useHasPurchasedSubscription(businessId);

  const allChecked = hasInvitedUser && isSSOActive && isSCIMActive && isDomainMatchingOn && hasPurchasedSubscription;

  return (
    <section>
      <h3 className="lds-h4">
        {allChecked ? "Great, your account is fully configured!" : "You’re almost there, what’s next?"}
      </h3>
      <ul className={styles.whatNextList}>{children}</ul>
    </section>
  );
}

function CheckItem({
  checked,
  to,
  onClick,
  children,
}: {
  checked: boolean;
  to?: LinkProps["to"];
  onClick?(): void;
  children: React.ReactNode;
}) {
  return (
    <li className={clsx(styles.checkItem, { [styles.checked]: checked })}>
      <span role="checkbox" aria-checked={checked} aria-readonly>
        <CheckIcon className={styles.checkMark} />
      </span>
      {to ? (
        <Link<any> className={styles.checkItemLabel} to={to}>
          {children}
        </Link>
      ) : null}
      {onClick ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className={styles.checkItemLabel}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          {children}
        </a>
      ) : null}
      {!to && !onClick ? <span className={styles.checkItemLabel}>{children}</span> : null}
    </li>
  );
}

export function SetUpDomainMatching() {
  const businessId = useBusinessIdParam();
  const { isDomainMatchingOn } = useIsDomainMatchingOn(businessId);

  return (
    <CheckItem checked={isDomainMatchingOn} to={`/business/${businessId}/domain-matching`}>
      Setup Domain Matching
    </CheckItem>
  );
}

export function InviteUser() {
  const businessId = useBusinessIdParam();
  const { hasInvitedUser } = useHasInvitedUser(businessId);

  return (
    <CheckItem checked={hasInvitedUser} to={`/business/${businessId}/users`}>
      Get other people to join your Organization
    </CheckItem>
  );
}

export function PurchaseSubscription() {
  const businessId = useBusinessIdParam();
  const { business } = useBusiness(businessId);
  const { businessUsers } = useBusinessUsers(businessId);
  const { hasPurchasedSubscription } = useHasPurchasedSubscription(businessId);

  return (
    <CheckItem
      checked={hasPurchasedSubscription}
      onClick={() => {
        openUrl(
          `${pricingUrl}?businessId=${businessId}&quantity=${businessUsers.length}&company_name=${
            business?.name ?? ""
          }`,
          true,
        );
      }}
    >
      Purchase Lens subscription
    </CheckItem>
  );
}

export function ConfigureSSO() {
  const businessId = useBusinessIdParam();
  const { isSSOActive } = useIsSSOActive(businessId);

  return (
    <CheckItem checked={isSSOActive} to={`/business/${businessId}/auth`}>
      Configure Single-Sign On
    </CheckItem>
  );
}

export function ConfigureSCIM() {
  const businessId = useBusinessIdParam();
  const { isSCIMActive } = useIsSCIMActive(businessId);

  return (
    <CheckItem checked={isSCIMActive} to={`/business/${businessId}/auth`}>
      Enable SCIM provisioning
    </CheckItem>
  );
}
